import {
  MODAL_TYPE_SHARE_POST,
  MODAL_TYPE_SHARE_LINK,
  MODAL_TYPE_SHARE_COMMENT,
  MODAL_TYPE_DELETE_COMMENT,
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_POST_SETTINGS,
  MODAL_TYPE_DISCARD_POST_SETTINGS,
  MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS,
  MODAL_TYPE_POST_EDITOR,
  MODAL_POST_FORM_ACTIONS,
  MODAL_TYPE_ADD_CATEGORIES,
  MODAL_TYPE_DISCARD_POST_DRAFT,
  MODAL_TYPE_DEMO_MODE,
} from '@wix/communities-blog-client-common';

import SharePostModal from '../../../common/components/modals/share-post-modal/share-post-modal-component';
import ShareLinkModal from '../../../common/components/modals/share-link-modal/share-link-modal';
import ShareCommentModal from '../../../comments/containers/share-comment-modal';
import DeleteCommentModal from '../../../comments/containers/delete-comment-modal';
import PostSettingsModal from '../../../post-settings/containers/post-settings-modal/post-settings-modal';
import DiscardPostSettingsModal from '../../../post-settings/containers/discard-post-settings-modal';
import AddCategoriesMobileModal from '../../../post-settings/containers/add-categories-mobile-modal';
import DeletePostModal from '../../containers/delete-post-modal';
import PostFormEditInProgressModal from '../../containers/post-form-edit-in-progress-modal';
import PostEditorModal from '../../containers/post-editor-modal';
import PostFormActionSheet from '../../containers/post-form-action-sheet';
import DiscardPostDraftModal from '../../containers/discard-post-draft-modal';
import DemoModeModal from '../../containers/demo-modal';

export const modalComponentMapByType = {
  [MODAL_TYPE_SHARE_POST]: SharePostModal,
  [MODAL_TYPE_SHARE_LINK]: ShareLinkModal,
  [MODAL_TYPE_SHARE_COMMENT]: ShareCommentModal,
  [MODAL_TYPE_DELETE_COMMENT]: DeleteCommentModal,
  [MODAL_TYPE_POST_SETTINGS]: PostSettingsModal,
  [MODAL_TYPE_DISCARD_POST_SETTINGS]: DiscardPostSettingsModal,
  [MODAL_TYPE_DELETE_POST]: DeletePostModal,
  [MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS]: PostFormEditInProgressModal,

  [MODAL_TYPE_POST_EDITOR]: PostEditorModal,
  [MODAL_POST_FORM_ACTIONS]: PostFormActionSheet,
  [MODAL_TYPE_ADD_CATEGORIES]: AddCategoriesMobileModal,
  [MODAL_TYPE_DISCARD_POST_DRAFT]: DiscardPostDraftModal,

  [MODAL_TYPE_DEMO_MODE]: DemoModeModal,
};
