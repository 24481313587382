import { dateLib } from '@wix/communities-blog-client-common';

export const calculateReadingTimeProps = ({
  scrollPosition,
  scrollableHeight,
  clientHeight,
  browserWidth,
  readingStartTime,
  contentContainer,
}) => ({
  percent_scrolled: ((scrollPosition / scrollableHeight) * 100).toFixed(2),
  browser_width: browserWidth,
  view_start: readingStartTime.format('YYYY-MM-DD HH:mm:ss.SSS'),
  timediff: dateLib.utc().diff(readingStartTime),
  scrollable_heights: scrollableHeight,
  scrolled_bottom: scrollPosition + clientHeight,
  scrolled_top: scrollPosition,
  bottom_position: contentContainer ? contentContainer.bottom + scrollPosition : 0,
  top_position: contentContainer ? contentContainer.top + scrollPosition : 0,
});
