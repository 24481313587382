import React, { Component } from 'react';
import { flowRight, pick } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PostSettingsInputLabel from './post-settings-input-label';
import POST_SETTINGS_CATEGORY_FORM_CONFIG from '../services/post-settings-category-form-config';
import withFastForm from '../../common/components/fast-form/with-fast-form';
import withTranslate from '../../common/hoc/with-translate';
import withSettingsColor from '../../common/hoc/with-settings-color';
import withCardBackgroundColor from '../../common/hoc/with-card-background-color';
import { MAX_CATEGORY_LABEL_LENGTH, POST_TEXT_COLOR_PATH } from '@wix/communities-blog-client-common';
import { ConfirmIcon } from '../../common/components/icons/confirm-icon';
import styles from './post-settings-category-form.scss';

const dataHook = postfix => `post-settings-category-form_${postfix}`;

class PostSettingsCategoryForm extends Component {
  textInput = null;
  setTextInputRef = element => (this.textInput = element);
  focusTextInput = () => this.textInput && this.textInput.focus();

  componentDidMount() {
    this.focusTextInput();
  }

  handleFormSubmit = () => {
    const { fastForm } = this.props;
    if (!fastForm.isValid) {
      return;
    }
    fastForm.submit();
    fastForm.resetForm();
  };

  getBorderStyle = focused => {
    const { borderColorFocused, borderColorNotFocused } = this.props;
    return {
      border: `1px solid ${focused ? borderColorFocused : borderColorNotFocused}`,
    };
  };

  render() {
    const {
      fastForm: {
        fields: { label },
        isValid,
      },
      cardBackgroundColor,
      t,
    } = this.props;
    const inputProps = pick(label, ['onBlur', 'onChange', 'onFocus', 'value']);

    return (
      <div className={styles.container}>
        <PostSettingsInputLabel
          htmlFor="category"
          labelText={t('post-settings-category-form.label')}
          limit={MAX_CATEGORY_LABEL_LENGTH}
          value={inputProps.value}
          showInfo={true}
        />
        <div
          className={styles.inputContainer}
          style={{ backgroundColor: cardBackgroundColor, ...this.getBorderStyle(label.active) }}
        >
          <input
            id="category"
            className={classNames('blog-text-color', styles.input)}
            maxLength={MAX_CATEGORY_LABEL_LENGTH}
            placeholder={t('post-settings-category-form.placeholder')}
            data-hook={dataHook('input')}
            ref={this.setTextInputRef}
            {...inputProps}
          />
          {isValid && (
            <button
              onClick={this.handleFormSubmit}
              className={classNames('blog-button-background-color', styles.button)}
              data-hook={dataHook('submit')}
            >
              <ConfirmIcon className="blog-button-primary-icon-fill" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

PostSettingsCategoryForm.propTypes = {
  borderColorFocused: PropTypes.string.isRequired,
  borderColorNotFocused: PropTypes.string.isRequired,
  cardBackgroundColor: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default flowRight(
  withFastForm(POST_SETTINGS_CATEGORY_FORM_CONFIG),
  withSettingsColor(POST_TEXT_COLOR_PATH, 'borderColorFocused', 'color-5', 0.5, '#000000'),
  withSettingsColor(POST_TEXT_COLOR_PATH, 'borderColorNotFocused', 'color-5', 0.2, '#000000'),
  withCardBackgroundColor,
  withTranslate,
)(PostSettingsCategoryForm);
