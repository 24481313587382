import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { POST_TEXT_COLOR_PATH } from '@wix/communities-blog-client-common';
import withCardBackgroundColor from '../../common/hoc/with-card-background-color';
import withSettingsColor from '../../common/hoc/with-settings-color';
import Tooltip from '../../common/components/tooltip';
import IconTooltip from '../../common/components/icon-tooltip';
import { HelpIcon } from '../../common/components/icons/help-icon';
import styles from './post-settings-label-with-tooltip.scss';

// eslint-disable-next-line react/prop-types
const getTooltipElement = ({ tooltipText, backgroundColor, borderColor }) => (
  <Tooltip
    className={classNames(styles.tooltip, 'blog-default-card-background-color', 'blog-card-border-color')}
    style={{ backgroundColor, borderWidth: '1px', borderStyle: 'solid', borderColor, zIndex: 1000000000 }}
    triangle={{
      borderColorClass: classNames('blog-default-card-border-color', 'blog-card-border-bottom-color'),
      borderBottomColorClass: 'blog-default-card-border-bottom-color',
      backgroundColor,
      borderColor,
    }}
  >
    <div className={classNames(styles.text, 'blog-text-color')}>{tooltipText}</div>
  </Tooltip>
);

const PostSettingsLabelWithTooltip = ({
  labelText,
  labelProps,
  tooltipText,
  borderColor,
  buttonText,
  buttonProps: { className: buttonClassName, ...btnProps },
  cardBackgroundColor,
}) => (
  <div className={styles.container}>
    <label {...labelProps}>{labelText}</label>
    <span className={styles.spacing} />
    {tooltipText && (
      <IconTooltip
        TooltipElement={getTooltipElement({ tooltipText, backgroundColor: cardBackgroundColor, borderColor })}
        Icon={HelpIcon}
        activeIconClassName="blog-icon-secondary-fill"
        inactiveIconClassName="blog-icon-fill"
      />
    )}
    {buttonText && (
      <button {...btnProps} className={classNames(styles.button, buttonClassName, 'blog-button-color')}>
        {buttonText}
      </button>
    )}
  </div>
);

PostSettingsLabelWithTooltip.propTypes = {
  labelText: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  tooltipText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
  borderColor: PropTypes.string.isRequired,
  cardBackgroundColor: PropTypes.string,
};

PostSettingsLabelWithTooltip.defaultProps = {
  labelProps: {},
  buttonProps: {},
};

// prettier-ignore
export default flowRight(
  withSettingsColor(POST_TEXT_COLOR_PATH, 'borderColor', 'color-5', 0.2),
  withCardBackgroundColor
)(PostSettingsLabelWithTooltip);
