import { FIELD_ERROR_URL } from '@wix/communities-blog-client-common';
import { isValidUrl } from '../../common/services/is-valid-url';
import { POST_SETTINGS_FIELDS } from '@wix/communities-blog-universal/dist/src/constants/post';

export const POST_SETTINGS_FORM_NAME = 'postSettings';

const POST_SETTINGS_FORM_CONFIG = {
  formName: POST_SETTINGS_FORM_NAME,
  fields: POST_SETTINGS_FIELDS,
  validate: ({ canonicalUrl }) => {
    const errors = {};

    if (canonicalUrl && !isValidUrl(canonicalUrl)) {
      errors.canonicalUrl = FIELD_ERROR_URL;
    }

    return errors;
  },
};

export default POST_SETTINGS_FORM_CONFIG;
