import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GatewayDest } from 'react-gateway';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import { NAVIGATION_TEXT_COLOR_PATH, MODAL_POST_FORM_ACTIONS } from '@wix/communities-blog-client-common';
import { isPublished } from '@wix/communities-blog-universal/dist/src/post-utils';
import PostFormActions from '../post-form-actions';
import { MoreButton } from '../../../common/components/more-button';
import MoreIcon from '../../../common/components/icons/more-icon';
import HeaderButton from '../../../common/components/header-button';
import LoadingBar from '../../../common/components/loading-bar';
import { getIsSavingPostDraft } from '../../../common/store/is-loading/is-loading-selectors';
import {
  getPostEditorDraftKey,
  getPostEditorPost,
  getPostEditorPostId,
  getHasUnpublishedChanges,
} from '../../../common/store/post-editor/post-editor-selectors';
import withTranslate from '../../../common/hoc/with-translate';
import withButtonColor from '../../../common/hoc/with-button-color';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withExperiment from '../../../common/hoc/with-experiment';
import withSettingsColor from '../../../common/hoc/with-settings-color';
import withPostPublish from '../../hoc/with-post-publish';
import { PostPublishButton } from '../publish-button/publish-button';
import styles from './post-editor-mobile-header.scss';

const TIMEOUT = 1200;

class PostEditorMobileHeader extends Component {
  state = {
    isStatusVisible: false,
  };

  handleIsSavingChange(oldValue, newValue) {
    if (oldValue && !newValue) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ isStatusVisible: false });
      }, TIMEOUT);
    }
  }

  componentWillReceiveProps({ isSavingDraft }) {
    const prevIsSavingDraft = this.props.isSavingDraft;
    this.handleIsSavingChange(prevIsSavingDraft, isSavingDraft);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getCategoryIds() {
    return this.props.fastForm.fields.categoryIds.value || [];
  }

  renderStatus() {
    const { isSavingDraft } = this.props;
    const translationKey = isSavingDraft
      ? 'post-editor-mobile-header.status-saving'
      : 'post-editor-mobile-header.status-saved';
    return <HeaderButton text={translationKey} />;
  }

  renderCta() {
    return this.props.isPublished ? this.renderUpdateButton() : this.renderSaveButton();
  }

  renderUpdateButton() {
    return (
      <PostPublishButton>
        <HeaderButton text="post-editor-mobile-header.update-button-label" />
      </PostPublishButton>
    );
  }

  renderSaveButton() {
    return (
      <HeaderButton
        text="post-editor-mobile-header.save-button-label"
        onClick={() => {
          this.setState({ isStatusVisible: true }, this.props.savePostDraft);
          this.props.openModal(MODAL_POST_FORM_ACTIONS, { categoryIds: this.getCategoryIds() });
        }}
      />
    );
  }

  renderDropdownButton() {
    const { postId, isPublished, hasUnpublishedChanges } = this.props;
    const icon = <MoreIcon style={{ display: 'block' }} className="blog-navigation-container-fill" />;
    const actions = [
      PostFormActions.ACTION_ADD_CATEGORY,
      isPublished && hasUnpublishedChanges ? PostFormActions.ACTION_DISCARD : null,
      isPublished ? PostFormActions.ACTION_DELETE : null,
    ];

    return (
      <MoreButton icon={icon} className={styles.dropdown}>
        <PostFormActions postId={postId} actions={actions} />
      </MoreButton>
    );
  }

  render() {
    const { isStatusVisible } = this.state;
    const {
      onCancel,
      hideNavBar,
      contentFontClassName,
      buttonColor,
      isMobileMenuColorMatchDesktopExperimentEnabled,
      navigationDividerColor,
    } = this.props;
    const left = (
      <HeaderButton
        text="post-editor-mobile-header.close-button-label"
        onClick={onCancel}
        data-hook="post-editor-mobile-header__cancel"
      />
    );
    const right = (
      <div className={styles.right}>
        {isStatusVisible ? this.renderStatus() : this.renderCta()}
        {this.renderDropdownButton()}
      </div>
    );
    const menu = (
      <div style={{ height: 50 }}>
        <GatewayDest name="mobile-header" />
      </div>
    );

    const containerClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? classNames(
          styles.headerContainer,
          'blog-navigation-container-color',
          'blog-header-background-color',
          'blog-navigation-container-font',
        )
      : classNames(styles.headerContainer, 'blog-button-primary-text-color', contentFontClassName);

    const wrapperClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? styles.header
      : classNames(styles.header, 'blog-default-button-background-color');

    const wrapperStyle = isMobileMenuColorMatchDesktopExperimentEnabled
      ? { borderBottom: `1px solid ${navigationDividerColor}` }
      : { backgroundColor: buttonColor };

    return (
      <div className={containerClassName}>
        <LoadingBar className={styles.loadingBar} />
        {!hideNavBar && (
          <div className={wrapperClassName} style={wrapperStyle}>
            {left}
            {right}
          </div>
        )}
        <div style={wrapperStyle}>{menu}</div>
      </div>
    );
  }
}

PostEditorMobileHeader.propTypes = {
  t: PropTypes.func,
  openModal: PropTypes.func,
  onCancel: PropTypes.func,
  isSavingDraft: PropTypes.bool,
  isPublished: PropTypes.bool,
  hasUnpublishedChanges: PropTypes.bool,
  postId: PropTypes.string,
  hideNavBar: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  buttonColor: PropTypes.string,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
  navigationDividerColor: PropTypes.string,
};

const mapStateToProps = (state, ownProps, actions) => ({
  postId: getPostEditorPostId(state),
  isSavingDraft: getIsSavingPostDraft(state, getPostEditorDraftKey(state)),
  isPublished: isPublished(getPostEditorPost(state)),
  hasUnpublishedChanges: getHasUnpublishedChanges(state),
  openModal: actions.openModal,
});

export default flowRight(
  withButtonColor,
  withFontClassName,
  withSettingsColor(NAVIGATION_TEXT_COLOR_PATH, 'navigationDividerColor', 'color-5', 0.2, '#000000'),
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled: EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
  connect(mapStateToProps),
  withPostPublish({ skipInitialize: true }),
  withTranslate,
)(PostEditorMobileHeader);
