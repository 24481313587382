import { trim } from 'lodash';
import { MAX_CATEGORY_LABEL_LENGTH, FIELD_ERROR_REQUIRED } from '@wix/communities-blog-client-common';

export const POST_SETTINGS_CATEGORY_FORM_NAME = 'post-settings-category';

const isLabelInvalid = label => !trim(label) || label.length > MAX_CATEGORY_LABEL_LENGTH;

const POST_SETTINGS_CATEGORY_FORM_CONFIG = {
  formName: POST_SETTINGS_CATEGORY_FORM_NAME,
  fields: ['label'],
  validate: ({ label }) => {
    const errors = {};

    if (isLabelInvalid(label)) {
      errors.label = FIELD_ERROR_REQUIRED;
    }

    return errors;
  },
  initialValues: {
    label: '',
  },
};

export default POST_SETTINGS_CATEGORY_FORM_CONFIG;
