import { noop, get } from 'lodash';
import React, { Suspense } from 'react';
import { connect } from '../../../common/components/runtime-context';
import Loader from '../../../common/components/loader';
import { getLocale } from '../../../common/selectors/locale-selectors';
import { isSSR } from '../../../common/services/is-ssr';
import { useNextRCE } from '../../services/config';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_RICOS } from '@wix/communities-blog-experiments';

const importRCE = (locale = 'en', isUseRicosExperimentEnabled = false) => async () => {
  if (useNextRCE && isUseRicosExperimentEnabled) {
    return import(/* webpackChunkName: "ricos-editor" */ './ricos-editor');
  }
  const [rceModule, translationsModule] = await Promise.all([
    import(/* webpackChunkName: "rich-content-editor" */ './rich-content-editor'),
    import(
      /* webpackChunkName: "rich-content-common/[request]" */ `wix-rich-content-common/dist/statics/locale/messages_${locale}.json`
    ).catch(noop),
  ]);
  return { ...rceModule, default: rceModule.default(get(translationsModule, 'default')) };
};

const createLazyRCE = () => {
  let Component;

  const Lazy = props => {
    if (isSSR()) {
      return <Loader />;
    }

    if (!Component) {
      Component = React.lazy(importRCE(props.locale, props.isUseRicosExperimentEnabled));
    }

    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };

  const mapRuntimeToProps = state => ({
    locale: getLocale(state),
    isUseRicosExperimentEnabled: isExperimentEnabled(state, EXPERIMENT_USE_RICOS),
  });

  return connect(mapRuntimeToProps)(Lazy);
};

export default createLazyRCE();
