import { concat, trim } from 'lodash';
import { POST_SETTINGS_FIELDS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { FIELD_ERROR_REQUIRED } from '@wix/communities-blog-client-common';
import { isContentStateEmpty } from '../../common/services/content-state-utils';

export const POST_FORM_NAME = 'post';

const POST_FORM_CONFIG = {
  formName: POST_FORM_NAME,
  fields: concat(['title', 'content'], POST_SETTINGS_FIELDS),
  validate: values => {
    const errors = {};
    const { content, title } = values;

    if (!trim(title)) {
      errors.title = FIELD_ERROR_REQUIRED;
    }

    if (!content || isContentStateEmpty(content)) {
      errors.content = FIELD_ERROR_REQUIRED;
    }

    return errors;
  },
};

export default POST_FORM_CONFIG;
